<template lang="pug">
TableV2(
  :headers="headers"
  :items="positions"
  :pageCount="0"
  :currentPage="0"
  #TODO remade function call
  :querySearch="()=>{}"
  isHiddenTableCount
  isHiddenPageCount
  isHiddenTableSearch
  showExpand
  componentEdit="BackOfficeMedicalEdit"
  :iconExpandedOpen="iconExpandedOpen"
)
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    row: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      headers: [
        { value: 'medical_institution', text: this.$t('nameMedicalInstitution'), sortable: false },
        { value: 'medical_position', text: this.$t('position'), sortable: false },
        { value: 'price', text: this.$t('price'), sortable: false },
        { value: 'date_start', text: this.$t('dateEffective'), sortable: false },
        { value: 'date_end', text: this.$t('dateEnd'), sortable: false },
        { value: 'event', text: this.$t('actions'), sortable: false },
        { text: ``, value: 'data-table-expand' }
      ],
      iconExpandedOpen: {
        name: 'mdi-pen',
        color: 'blue',
        tooltip: 'tooltip.edit'
      }
    }
  },
  computed: {
    ...mapState({
      items: (state) => state.backoffice.medical
    }),
    showExpandValue () {
      return !!this.row.date_end
    },
    positions () {
      return this.row.positions.map(item => {
        item.isHiddenEdit = Boolean(item.date_end) && new Date(item.date_start) < new Date()
        return item
      })
    }
  }
}
</script>
